.file-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.trash-icon {
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
}

.trash-icon:hover {
  filter: brightness(0.5);
}

.file-info {
  flex: 1;
  align-items: center;
  column-gap: 0.75rem;
}

.name {
  font-weight: 500;
  line-height: 1.25rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 20rem;
  color: rgb(31 41 55);
  margin: 0;
}

.size {
  font-size: 0.75rem;
  line-height: 1rem;
  color: rgb(107 114 128);
  margin: 0;
}

.upload-info {
  display: inline-flex;
  align-items: center;
  column-gap: 0.5rem;
}

.progress[status="warning"] {
  background-color: rgb(254 249 195); /* bg-yellow-100 */
  color: rgb(133 77 14); /* text-yellow-800 */
}

.progress[status="uploading"] {
  background-color: rgb(219 234 254); /* bg-blue-100 */
  color: rgb(30 64 175); /* text-blue-800 */
}

.progress[status="validating"] {
  background-color: rgb(219 234 254); /* bg-blue-100 */
  color: rgb(30 64 175); /* text-blue-800 */
}

.progress[status="valid"] {
  background-color: rgb(209 250 229); /* bg-emerald-100 */
  color: rgb(4 120 87); /* text-emerald-700 */
}

.icon {
  display: flex;
  align-items: center;
  svg {
    height: 1em;
  }

  svg.spinner {
    height: 1.5em;
  }
}

.progress {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  min-width: 7rem;
  border-radius: 0.25rem;
  padding: 0.25rem 0.5rem;
  column-gap: 0.5rem;
}

.spinner {
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
