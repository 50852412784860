/* eslint-disable lit-a11y/click-events-have-key-events */
import { LitElement, html, css, nothing } from "lit"
import { unsafeSVG } from "lit/directives/unsafe-svg.js"
import { unsafeHTML } from "lit/directives/unsafe-html.js"

import filesIcon from "../../../assets/images/icons/files.svg"

import styles from "../../styles/niva_document_input/upload_area.css"

import "./file_item"

class UploadArea extends LitElement {
  static styles = css([styles])
  static properties = {
    t: { type: Object },
    status: { type: String, reflect: true },
    disabled: { type: Boolean, reflect: true },
    file: { type: Object },
    message: { type: String },
  }

  get uploadDisabled() {
    return ["warning", "valid", "validating"].includes(this.status)
  }

  resetFileInput() {
    this.renderRoot.querySelector("input[type='file']").value = ""
  }

  render() {
    const showMessage = this.status == "warning"

    return html`
      <div
        @click=${this.handleClick}
        @dragover=${this.#handleDragOver}
        @dragleave=${this.#handleDragLeave}
        @drop=${this.#handleDrop}
      >
        ${this.#renderPrompt()}
        ${this.#renderFailPrompt()}

        <file-item .t=${this.t} .status=${this.status} .file=${this.file}>
        </file-item>

        <div class="message" .innerText=${this.message} ?hidden=${!showMessage}>
        </div>
      </div>

      <input type="file" hidden @change=${(e) => this.#uploadFile(e.target)}>
      </input>
    `
  }

  #renderPrompt() {
    if (this.file || this.#isFailState()) return nothing

    return html`
      <div class="prompt">
        <div class="files-icon">${unsafeSVG(filesIcon)}</div>
        <div>${unsafeHTML(this.t.promptInstructions)}</div>
      </div>
    `
  }

  #isFailState() {
    return ["fail", "error", "invalid"].includes(this.status)
  }

  #renderFailPrompt() {
    if (!this.#isFailState()) return nothing

    return html`
      <div class="prompt">
        <div class="fail-message">${this.message}</div>
        <div class="upload-icon">${unsafeSVG(filesIcon)}</div>
        <div>${unsafeHTML(this.t.promptInstructions)}</div>
      </div>
    `
  }

  #handleDragOver(e) {
    e.preventDefault()
    if (this.uploadDisabled) return

    this.classList.add("dragover")
  }

  #handleDragLeave(e) {
    e.preventDefault()
    if (this.uploadDisabled) return

    this.classList.remove("dragover")
  }

  #handleDrop(e) {
    e.preventDefault()
    if (this.uploadDisabled) return

    this.classList.remove("dragover")
    this.#uploadFile(e.dataTransfer)
  }

  handleClick(e) {
    e.stopPropagation()
    if (this.uploadDisabled) return

    this.renderRoot.querySelector("input[type='file']").click()
  }

  #uploadFile(target) {
    const event = new CustomEvent("upload-file", {
      detail: { file: target.files[0] },
      bubbles: true,
    })
    this.dispatchEvent(event)
  }
}
customElements.define("upload-area", UploadArea)
