import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input"]

  connect() {
    this.form.addEventListener("submit", this.checkRequired)
  }

  disconnect() {
    this.form.removeEventListener("submit", this.checkRequired)
  }

  get form() {
    return this.inputTarget.closest("form")
  }

  checkRequired = (e) => {
    if (!this.inputTarget.checkValidity()) {
      e.preventDefault()
      this.inputTarget.setError("This document is required.")
    }
  }
}
