import { LitElement, html } from "lit"

import "./niva_document_input/upload_area"
import FileService from "./niva_document_input/file_service"
import translations from "./niva_document_input/translations.json"

export default class NivaDocumentInput extends LitElement {
  static formAssociated = true

  static properties = {
    submissionId: { type: String },
    locale: { type: String },
    documentId: { type: String, reflect: true },
    documentType: { type: String },
    apiKey: { type: String },
    file: { type: Object, state: true },
    message: { type: Object, state: true },
    status: { type: String, state: true, reflect: true },
  }

  #internals = this.attachInternals()
  #value = ""

  get value() {
    return this.#value
  }

  get form() {
    return this.#internals.form
  }

  checkValidity() {
    return this.#internals.checkValidity()
  }

  setError(message) {
    this.#updateFileState({ file: this.file, status: "invalid", message })
  }

  clearError() {
    this.#updateFileState({ file: this.file, status: "", message: "" })
  }

  get #uploadArea() {
    return this.renderRoot.querySelector("upload-area")
  }

  set value(newValue) {
    this.#value = newValue

    this.#internals.setFormValue(this.#value)

    if (this.hasAttribute("required") && !this.#value) {
      this.#internals.setValidity(
        { valueMissing: true },
        this.t.errors.required,
      )
    }
  }

  connectedCallback() {
    super.connectedCallback()

    this.locale = this.locale || "en"
    this.t = translations[this.locale]

    this.setAttribute("tabindex", "0")
    this.addEventListener("keydown", this.#handleKeyDown)

    this.fileService = new FileService(
      this.documentType,
      this.submissionId,
      this.apiKey,
      this.t,
    )

    this.value = this.documentId
    this.fileService.get(this.documentId, { onSuccess: this.#showFile })
  }

  disconnectedCallback() {
    this.removeEventListener("keydown", this.#handleKeyDown)
    super.disconnectedCallback()
  }

  render() {
    return html`
      <upload-area
        .t=${this.t}
        .status=${this.status}
        .file=${this.file}
        .message=${this.message}
        @upload-file=${this.#uploadFile}
        @delete-file=${this.#deleteFile}
      ></upload-area>
    `
  }

  #updateFileState({ file, status, message }) {
    this.documentId = file?.id
    this.value = this.documentId

    this.file = file
    this.status = status
    this.message = message
  }

  #clearFile() {
    this.#uploadArea.resetFileInput()
  }

  #showError(error) {
    this.#updateFileState({ file: null, status: "error", message: error })
  }

  #uploadFile = (e) => {
    const file = e.detail.file

    if (!this.#validate(file)) return

    this.#updateFileState({ file, status: "uploading", message: "" })
    this.fileService.upload(file, {
      onSuccess: (_blob) => {
        this.#updateFileState({ file, status: "validating", message: "" })
      },
      onError: (error) => {
        this.#showError(error)
      },
      onValidated: (file, _warning) => {
        this.#showFile(file)
      },
    })
  }

  #validate = (file) => {
    const maxFileSize = 50 * 1024 * 1024 // 50 MB
    if (file && file.size > maxFileSize) {
      const message = this.t.errors.fileSizeTooBig
      this.#updateFileState({ file: null, status: "invalid", message })
      this.#internals.setValidity({ customError: true }, message)
      this.#clearFile()

      return false
    }

    const allowedTypes = ["image/jpeg", "image/png", "application/pdf"]
    if (file && !allowedTypes.includes(file.type)) {
      const message = this.t.errors.unsupportedFileType
      this.#updateFileState({ file: null, status: "invalid", message })
      this.#internals.setValidity({ customError: true }, message)
      this.#clearFile()

      return false
    }

    return true
  }

  #handleKeyDown = (e) => {
    if (e.key === "Enter" || e.key === " ") {
      this.#uploadArea.handleClick()
    }
  }

  #showFile = (file) => {
    if (!file) return
    if (file.status === "fail" || file.status === "error") {
      this.#clearFile()
    }

    const status = file.status || "uploading"
    const message = file.message || ""
    this.#updateFileState({ file, status, message })
  }

  #deleteFile = (e) => {
    this.#clearFile()

    this.fileService.delete(e.detail.id, {
      onSuccess: () =>
        this.#updateFileState({ file: null, status: "", message: "" }),
      onError: (_error) =>
        this.#showError(new Error(this.t.errors.removalFailed)),
    })
  }
}
