import { DirectUpload } from "@rails/activestorage"

export default class FileService {
  constructor(docType, submissionId, apiKey, t) {
    this.docType = docType
    this.apiKey = apiKey
    this.t = t
    this.apiBase = "http://api.niva.local:3000/v1"
    this.apiURL = `${this.apiBase}/internal/submissions/${submissionId}/documents`
    this.file = null
  }

  get headers() {
    return {
      headers: {
        apiKey: this.apiKey,
        Accept: "application/json",
        "Accept-Language": this.t.locale,
      },
    }
  }

  async get(id, callbacks) {
    if (!id) return

    const response = await fetch(`${this.apiURL}/${id}`, this.headers)

    if (response.ok) {
      callbacks.onSuccess?.(await response.json())
    } else {
      callbacks.onError?.(new Error(this.t.errors.uploadFailed))
    }
  }

  upload(file, callbacks) {
    this.file = file

    const url = `${this.apiURL}/direct_upload`
    const upload = new DirectUpload(file, url, this, this.headers["headers"])

    upload.create((error, blob) => {
      if (error) {
        callbacks.onError?.(new Error(this.t.errors.uploadFailed))
      } else {
        callbacks.onSuccess?.(blob)
        this.validate(blob, file, callbacks)
      }
    })
  }

  async validate(blob, file, callbacks) {
    const formData = new FormData()
    formData.append("document_type", this.docType)
    formData.append("signed_blob_id", blob.signed_id)

    const options = { method: "POST", body: formData, ...this.headers }
    const response = await fetch(this.apiURL, options)

    if (!response.ok) {
      callbacks.onError?.(new Error(this.t.errors.uploadFailed))
      return
    }

    const data = await response.json()
    if (this.file !== file) {
      this.delete(data.id, {})
    } else {
      callbacks.onValidated?.(data, data.warning || "")
    }
  }

  async delete(id, callbacks) {
    this.file = null

    if (!id) {
      callbacks.onSuccess?.()
      return
    }

    const url = `${this.apiURL}/${id}`
    const response = await fetch(url, { method: "DELETE", ...this.headers })

    if (!response.ok) {
      callbacks.onError?.(new Error(this.t.errors.uploadFailed))
    } else {
      callbacks.onSuccess?.(response)
    }
  }
}
