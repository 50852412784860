{
  "en": {
    "locale": "en",
    "statuses": {
      "uploading": "Uploading...",
      "validating": "Validating...",
      "valid": "Done",
      "warning": "Warning"
    },
    "errors": {
      "uploadFailed": "Upload failed. Please try again.",
      "removalFailed": "Removal failed. Please try again.",
      "fileSizeTooBig": "Please choose a file smaller than 50 MB.",
      "unsupportedFileType": "Please upload an image or PDF.",
      "required": "Valid file is required."
    },
    "promptInstructions": "Drag & drop files here or <span class=\"link\">click</span> to upload"
  },
  "es": {
    "locale": "es",
    "statuses": {
      "uploading": "Subiendo...",
      "validating": "Validando...",
      "valid": "Listo",
      "warning": "Aviso"
    },
    "errors": {
      "uploadFailed": "La carga falló. Por favor, inténtalo de nuevo.",
      "removalFailed": "La eliminación falló. Por favor, intenta de nuevo.",
      "fileSizeTooBig": "El archivo es demasiado grande. Por favor, selecciona uno menor de 50 MB.",
      "unsupportedFileType": "Tipo de archivo no válido. Por favor, sube un archivo JPG, PNG o PDF.",
      "required": "Se requiere un archivo válido."
    },
    "promptInstructions": "Suelta tu archivo aquí o haz<span class=\"link\">clic</span> para buscar"
  }
}
