:host {
  border: 2px dashed rgb(214 211 209);
  border-radius: .75rem;
  margin-top: 1rem;
  padding: 20px;
  color: #6b7280;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 4rem;
  font-size: 0.875rem;

  transition-property:
    color,
    background-color,
    border-width,
    border-color,
    text-decoration-color,
    fill,
    stroke,
    opacity,
    box-shadow,
    transform,
    filter,
    backdrop-filter;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
  transition-duration: .15s;
}

.upload-icon {
  display: flex;
  width: 2.5rem;
}

.files-icon {
  width: 2.5rem;
}

input[type="file"] {
  display: none;
}

.message {
  line-height: 1.25rem;
  border: 0 solid #e5e7eb;
  border-top-width: 1px;
  padding-top: 0.75rem;
  margin-top: 0.75rem;
}

.prompt {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  row-gap: 0.75rem;
}

.link {
  color: rgb(14 195 80);
}

.link:hover {
  text-decoration: underline;
}

.fail-message {
  font-weight: 500;
}

:host([status="error"]) .fail-message {
  color: rgb(185 28 28); /* text-red-700 */
}

:host([status="fail"]),
:host([status="invalid"]) {
  background-color: rgb(254 226 226); /* bg-red-100 */
  border-color: rgb(239 68 68);
  color: rgb(185 28 28); /* bg-red-500 */
}

:host([status="warning"]) {
  background-color: rgb(255, 251, 235);
  border: 1px solid rgb(251, 191, 36);
}

:host([status="valid"]) {
  cursor: default;
  border: 1px solid rgb(214 211 209);
}

:host(:not([status]):hover),
:host([status=""]:hover),
:host([status="error"]:hover),
:host(.dragover) {
  background-color: #f9fafb;
  border-color: rgb(214 211 209);
  color: #4b5563;
}

:host([status="fail"]:hover),
:host([status="invalid"]:hover),
:host(.dragover) {
  background-color: #f9fafb;
  border-color: rgb(214 211 209);
  color: #6b7280;
}
