import { LitElement, html, css, nothing } from "lit"
import { unsafeSVG } from "lit/directives/unsafe-svg.js"

import spinnerIcon from "../../../assets/images/icons/spinner.svg"
import checkIcon from "../../../assets/images/icons/check.svg"
import warningIcon from "../../../assets/images/icons/warning.svg"
import trashIcon from "../../../assets/images/icons/trash.svg"
import styles from "../../styles/niva_document_input/file_item.css"

class FileItem extends LitElement {
  static styles = css([styles])
  static properties = {
    t: { type: Object },
    status: { type: String },
    file: { type: Object },
  }

  render() {
    if (!this.file || this.status === "fail" || this.status === "error")
      return nothing

    const hideIcon = !["warning", "valid", "validating"].includes(this.status)

    return html`
      <div class="file-item">
        <div class="file-info">
          <p class="name">${this.file.name}</p>
          <p class="size">${this.#formatBytes(this.file.size)}</p>
        </div>
        <div class="upload-info">
          ${this.#renderUploadProgress()}
          <div
            class="trash-icon"
            tabindex="0"
            @click=${this.#deleteFile}
            @keypress=${this.#handleKeyPress}
            ?hidden=${hideIcon}
          >
            ${unsafeSVG(trashIcon)}
          </div>
        </div>
      </div>
    `
  }

  #renderUploadProgress() {
    const iconMap = {
      uploading: spinnerIcon,
      validating: spinnerIcon,
      valid: checkIcon,
      warning: warningIcon,
    }
    return html`
      <span class="progress" status="${this.status}">
        <div class="icon">${unsafeSVG(iconMap[this.status])}</div>
        <span class="message">${this.t.statuses[this.status]}</span>
      </span>
    `
  }

  #formatBytes(bytes) {
    const units = ["B", "KB", "MB", "GB", "TB"]
    let size = bytes
    let unitIndex = 0

    while (size >= 1024 && unitIndex < units.length - 1) {
      size /= 1024
      unitIndex++
    }

    return `${size.toFixed(2)} ${units[unitIndex]}`
  }

  #handleKeyPress(e) {
    if (e.key === "Enter") {
      this.#deleteFile(e)
    }
  }

  #deleteFile(e) {
    e.stopPropagation()

    const event = new CustomEvent("delete-file", {
      detail: { id: this.file.id, status: this.status },
      bubbles: true,
      composed: true,
    })
    this.dispatchEvent(event)
  }
}
customElements.define("file-item", FileItem)
